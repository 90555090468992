<template>
    <div class="w-full h-full flex justify-center items-center flex-col gap-5">
        <div>
            <h1 class="text-[2rem] text-center sm:text-[50pt] text-white">Coming Soon</h1>
        </div>
        <MyFooterVue/>
    </div>
</template>

<script>
import MyFooterVue from './MyFooter.vue'
export default {
    components: {
        MyFooterVue
    }
}
</script>
<template>
  <div class="w-screen h-screen">
    <Background/>
    <MainContent/>
    <MyFooter/>
  </div>
</template>

<script>
import Background from './components/BlackFilter.vue'
import MainContent from './components/MainContent.vue';

export default {
  name: 'App',
  components: {
    Background,
    MainContent,
  }
}
</script>

<template>
  <div class="video-background_container">
    <video class="w-full min-h-full object-fill" autoplay=1 muted loop=1 ref="video">
      <source src="../assets/Videos/Black Coffee_1.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  data(){
    return{
      playInterval:null
    }
  },
  name: 'Back-ground',
  mounted(){
    this.playInterval = setInterval(()=>{
      this.$refs.video.play();
    });
  }

}
</script>
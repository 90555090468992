<template>
    <div>
        <h3 class="text-white mb-2 font-sans font-bold">Get In Touch With Us</h3>
        <div class="w-full flex justify-center min-h-10">
            <a href="https://www.instagram.com/kopihitam_indonesia?igsh=cm02YWZmcm91YjE4" class="transition-all hover:scale-125  hover:absolute hover:drop-shadow-[0px_0px_5px_rgba(255,255,255,1)]">
                <i class="bi bi-instagram text-white"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default{
    name:"MyFooter"
}

</script>